import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import BlogPage from '../components/Blog/blog'

const Blog = () => (
 <>
  <Header />
    <BlogPage />
  <Footer/>
  </>
)

export default Blog