import React from 'react'
import styles from './content.module.scss'
import { Link } from 'gatsby'
// import buttons from '../buttons.module.scss'

import comment from '../img/blog/Icon_comment.svg'
import eye from '../img/blog/Icon_eye.svg'
import linkedin from '../img/icons/linkedin.svg'
import twitter from '../img/icons/twitter.svg'
import facebook from '../img/icons/facebook.svg'

const BlogContent = ({ posts }) => {
  return (
    <>
      <div className={styles.content}>
        {posts.map((item, key) =>
          <div key={key + item.fields.md}>
            <div>
              <img className={styles.headerImg} src={item.fields.previewImg.fields.file.url} width="auto" height="auto" alt='img' />
              <img className={styles.icon} src={comment} width="auto" height="auto" alt='comment' /><img className={styles.iconEye} src={eye} width="auto" height="auto" alt='eye' />
            </div>
            <div className={styles.avatar}>
              <img src={item.fields.avatar.fields.file.url} width="auto" height="auto" alt="avatar" /><span>{item.fields.authorName}</span>
            </div>
            <div className={styles.social}>
              <a target='_blank' rel="noopener noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://duefocus.com/blog/${item.fields.md}/`}><img src={linkedin} width="auto" height="auto" alt="linkedin" /></a>
              <a target='_blank' rel="noopener noreferrer" href={`https://twitter.com/share?url=https://duefocus.com/blog/${item.fields.md}/`}><img src={twitter} width="auto" height="auto" alt="twitter" /></a>
              <div data-href={`https://duefocus.com/blog/${item.fields.md}/`}><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fduefocus.com%2F&amp;src=sdkpreparse"><img src={facebook} width="auto" height="auto" alt="facebook" /></a></div>
            </div>
            <div>
              <Link to={`/blog/${item.fields.md}/`}>
                <h3>{item.fields.title}</h3>
                <p>{item.fields.previewText}</p>
                <span>Read more</span>
              </Link>
            </div>
          </div>
        )}
        {/* <button className={buttons.buttonSignIn}>Load More</button> */}
      </div>
    </>
  )
}

export default React.memo(BlogContent);