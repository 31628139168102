import React, { useEffect, useState, memo } from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import moment from 'moment';
import styles from './blog.module.scss'
import Content from './content'
import LoaderThreeDots from '../Common/loader';
import buttons from '../buttons.module.scss'

import linkedin from '../img/icons/linkedin.svg'
import twitter from '../img/icons/twitter.svg'
import facebook from '../img/icons/facebook.svg'
import search from '../img/icons/Icon_search.svg'


const contentful = require('contentful')
const SPACE_ID = 'ybp5qgtpgrt5'
const ACCESS_TOKEN = 'K_7k8sA5YQcFC0FrjVJjPTxHS0q2JTNLE8amTFWV4EM'

const client = contentful.createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
})
const Blog = () => {

  const [data, setData] = useState({});
  const [lastPost, setLastPost] = useState();
  const [initialItems, setInitialItems] = useState({});
  const [isDataLoad, setDataLoad] = useState(true);

  useEffect(() => {
    client.getEntries()
      .then((response) => {
        setData(response.items);
        setInitialItems(response.items);
        setDataLoad(false);
        setLastPost(response.items[0]);
      }).catch(error => {
        console.log('blog.js getEntries error: ', error);
      })
  }, [])

  const handleSearchPosts = event => {
    const updatedList = _.filter(initialItems, post => {
      return post.fields.title.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setData(updatedList);
  };
  const tabView = category => {
    const updatedList = _.filter(initialItems, post => {
      return post.fields.category.toLowerCase().includes(category);
    });
    setData(updatedList);
  }
  return (
    <>
      <div className={styles.header}>
        {
          lastPost && <div className={styles.leftContent}>
            <div />
            <div>
              <div className={styles.avatar}>
                <img src={lastPost.fields.avatar.fields.file.url} width="auto" height="auto" alt="avatar" />
                <div>
                  <h2>{lastPost.fields.authorName}</h2>
                  <span>{moment(lastPost.fields.date).format('DD MMM YYYY')}</span>
                </div>
              </div>
              <h1>{lastPost.fields.title}</h1>
              <p>{lastPost.fields.previewText}</p>
              <Link to={`/blog/${lastPost.fields.md}/`}>Read more</Link>
            </div>
          </div>
        }
      </div>
      <div className={styles.search}>
        <div>
          <input onChange={handleSearchPosts} placeholder='Search in DueFocus Blog…' type="text" />
          <img src={search} width="auto" height="auto" alt="search" />
        </div>
        <div>
          <div>
            <a target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/shareArticle?mini=true&url=https://duefocus.com/blog'><img src={linkedin} width="auto" height="auto" alt="linkedin" /></a>
            <a target='_blank' rel="noopener noreferrer" href='https://twitter.com/share?url=http://duefocus.com/blog'><img src={twitter} width="auto" height="auto" alt="twitter" /></a>
            <div data-href="https://duefocus.com/blog"><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fduefocus.com%2F&amp;src=sdkpreparse"><img src={facebook} width="auto" height="auto" alt="facebook" /></a></div>
          </div>
          <button>Subscribe</button>
        </div>
      </div>
      {isDataLoad ? <LoaderThreeDots /> :
        <>
          <div className={styles.anchors}>
            <div onClick={() => setData(initialItems)}>All</div>
            <div onClick={() => tabView('taskManagement')}>Task Management</div>
            <div onClick={() => tabView('integrations')}>Integrations</div>
            <div onClick={() => tabView('timeTracking')}>Time Tracking</div>
            <div onClick={() => tabView('web')}>Web</div>
            <div onClick={() => tabView('features')}>Features</div>
            <div onClick={() => tabView('updates')}>Updates</div>
          </div>
          <div className={styles.main}>
            <Content posts={data} />
          </div>
        </>
      }
      <div className={styles.bottom}>
        <div>
          <h3>Get News from Duefocus!</h3>
          <p>Get Upates and excluseve access to contetn.</p>
        </div>
        <div className={styles.inputGroup}>
          <input placeholder='A place for your email' type="text" />
          <button className={buttons.buttonSignUp}>Subscribe</button>
        </div>
      </div>
    </>
  )
}

export default memo(Blog);